<script>
import {
    BTable,
    BCol,
    BRow,
    BFormInput,
    BButton,
    BCard,
    BCardBody,
    BMedia,
    BAvatar,
    BFormSelect,
    BLink,
    BFormFile,
    BCardText,
    BMediaBody,
    BForm,
    BListGroup,
    BListGroupItem,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import Loader from "../e-commerce/components/Loader.vue";
import Swal from "sweetalert2";
import axios from "axios";
import draggable from 'vuedraggable'
import {
    Trash2Icon,
    EditIcon,
    EyeOffIcon,
    XCircleIcon,
    DownloadIcon,
    EyeIcon,
    ExternalLinkIcon,
} from "vue-feather-icons";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import useModule from "./useModule";

export default {
    components: {
        BListGroup,
        BListGroupItem,
        draggable,
        BTable,
        BCol,
        BLink,
        XCircleIcon,
        DownloadIcon,
        BAvatar,
        BMedia,
        BRow,
        vSelect,
        BFormInput,
        BButton,
        BFormSelect,
        BCardBody,
        BCard,
        BFormFile,
        BCardText,
        BMediaBody,
        BForm,
        Loader,
        Trash2Icon,
        EditIcon,
        EyeOffIcon,
        EyeIcon,
        ExternalLinkIcon,
    },
    data() {
        return {
            searchQuery: "",

        };
    },
    setup() {
        const { items, } = useModule();

        return {
            items,
        };
    },
}
</script>
<template>
    <div>
        <b-card no-body class="mb-0">
            <div class="m-2">
                <b-row>
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <h3>Modules</h3>

                    </b-col>
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-button :to="{
                                name: 'add-new-module',
                            }" variant="primary">
                                <span class="text-nowrap">Add Module</span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </b-card>
        <div style="padding-top: 20px;">

        </div>
        <b-card no-body class="mb-0">
            <draggable :list="items" tag="ul" group="people" class="list-group list-group-flush cursor-move">
                <b-list-group-item v-for="(listItem, index) in items" :key="index" tag="li">
                    <div class="d-flex">
                        <b-avatar :text="listItem.title ? listItem.title.slice(0, 3) : ''" />
                        <div class="ml-25">
                            <b-card-text class="mb-0 font-weight-bold">
                                {{ listItem.title }}
                            </b-card-text>
                            <small>{{ listItem.title }}</small>
                        </div>
                    </div>
                    <div>
                        <b-list-group>
                            <b-list-group-item class="d-flex justify-content-between align-items-center"
                                v-for="(featureItem, i) in listItem.ModuleFeatures" :key="i">
                                <span>{{ featureItem.title }}</span>
                            </b-list-group-item>



                        </b-list-group>
                    </div>
                </b-list-group-item>
            </draggable>
        </b-card>
    </div>

</template>