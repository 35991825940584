
import { ref, onMounted } from "@vue/composition-api";
import axios from "@axios";

export default function useModule() {

      const items = ref([]);
      const topModules = ref([]);
    function fetchModules(){
            axios.get('/manage/modules').then((res) => {
                items.value = res.data.data;
               topModules.value = extractTopLevelTitles(items.value);
            }).catch((err) => {
                console.log(err);
            });
            
    }
    function extractTopLevelTitles(dataList) {
        const topLevelTitles = dataList.map(data => ({
            id: data.id,
            title: data.title
        }));
        topLevelTitles.unshift({ id: null, title: 'Select a Project Module' });
        return topLevelTitles;
    }

      async function createModuleFeature(submitForm) {
        try {
            const res = await axios.post('/manage/modulefeature', submitForm);
            return res.status === 200;
        } catch (err) {
            console.log(err);
            return false;
        }
    }

    onMounted(() => {
        fetchModules()
    })
    return {
        topModules,
        items,
        fetchModules,
        createModuleFeature,
    }
}

